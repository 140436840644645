<template>
	<div class="out_box">
		<main class="main-container">
			<div class="left-column">
				<LeftMenu />
			</div>
			<div class="right-column">
				<RightMenu :searchResults="searchResults" :search="search" :cid="cid" />
			</div>

		</main>
	</div>
</template>

<script>
import LeftMenu from '../components/LeftMenu.vue'
import RightMenu from '../components/RightMenu.vue'
// import indexApi from '../api/indexApi'
export default {
	name: 'HomeView',
	components: {
		LeftMenu,
		RightMenu
	},
	data() {
		return {
			// pageNum: 1,
			// pageSize: 35,
			searchResults: null,
			search:'',
			cid:'',
		}
	},
	watch:{
		'$route.query.search'(newValue) {
			this.search = newValue
		},
		'$route.query.id'(newValue){
			this.cid = newValue
		}
	},	
}
</script>

<style scoped lang="scss">
.out_box {
	width: 100vw;
	height: 100vh;
	/* max-width: 100%; */
	overflow: hidden;
}

.main-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	@include global.main-container;
	border-radius: 10px;
}

.left-column {
	/* width: 20%; */
	border-right: 1px solid #292929;
	height: 100%;
	display: flex;
	flex-direction: column;
	/* align-items: center; */
}

.right-column {
	/* width: 80%; */
	height: 100%;
	box-sizing: border-box;
	overflow-y: auto;
	/* 添加滚动条 */
}
</style>