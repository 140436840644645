<!-- src/views/AboutView.vue -->
<template>
	<div class="out_box">
		<div class="about">
			<div style="font-family: sans-serif; padding: 18px; color: white;"><br>
				<h2 style="font-family: sans-serif; color: white;">About Us !</h2>
				<h2 style="font-family: sans-serif; text-align: center;">Welcome To <span>MisGames</span></h2>
				<p><span>MisGames</span> is a Professional <span>Game</span> 
				Platform. Here we will only provide you with interesting
					content that you will enjoy very much. We are committed to providing you the best of <span>Game</span>,
					with a focus on reliability and <span>Game</span>. we strive to turn our passion for <span>Game</span>
					into a thriving website. We hope you enjoy our <span>Game</span> as much as we enjoy giving them to you.
				</p>
				<p>I will keep on posting such valuable anf knowledgeable information on my Website for
					all of you. Your love and support matters a lot.</p>
				<p style="font-weight: bold; text-align: center;">Thank you For Visiting Our Site<br><br data-v-9a698ac5="">
				<span style="color: blue; font-size: 16px; font-weight: bold; text-align: center;">Have a great day!</span></p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AboutView'
	}
</script>

<style scoped lang="scss">
	.out_box{
		width: 100vw;
		height: 100vh;
		background-color: global.$text-view;
	}
	.about {
		display: inline-block;
		margin-top: 6.25rem;
		padding: 0 15.625rem 15.625rem;
		line-height: 1.5625rem;
		
	}
</style>