<template>
	<view class="out_box">
		<GameIndex></GameIndex>
		
	</view>
	
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import GameIndex from './views/GameIndex.vue'

export default {
  name: 'App',
  components: {
    GameIndex
  }
}
</script>

<style>
.out_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: #0a1540; */
}
</style>
