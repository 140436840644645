import axios from 'axios';

const base_url = 'https://admin.towsgames.site'

const indexApi = {
  ApifoxModel: async (params) => {
    try {
      const response = await axios.get(base_url + '/api/index', { params });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },
  ApigetInfo: async (id) => {
    try {
      const response = await axios.get(base_url + `/api/getInfo/${id}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },
  ApigetClasslist: async () => {
    try {
      const response = await axios.get(base_url + `/api/classlist`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },
};

export default indexApi;