<template>
	<view class="left-menu">
		<ul class="menu-list">
			<li class="menu-item" v-for="(item, index) in leftMenuItems" :key="index" @click="goHome(item)">
				<view class="menu-item-content">
					<view class="menu-item-ico">
						<img :src="item.ico" alt="ico" class="menu-ico" />
					</view>
					<view class="menu-item-text">
						<a :href="item.href" class="menu-link" @mouseover="startShake" @mouseleave="stopShake">{{ item.name }}</a>
					</view>
				</view>
			</li>
		</ul>
	</view>
</template>

<script>
import indexApi from "../api/indexApi.js"
export default {
	name: 'LeftMenu',
	data() {
		return {
			leftMenuItems: [],
		}
	},
	mounted() {
		this.toLeftMenu();
	},
	methods: {
		async toLeftMenu() {
			try {
				const res = await indexApi.ApigetClasslist();
				const Classlist = res.data;
				const icos = 'https://admin.towsgames.site'
				this.leftMenuItems = Classlist.map(item => ({
					name: item.name,
					ico: icos + item.ico,
					id: item.id
				}));
			} catch (error) {
				console.error('获取数据失败:', error);
			}
		},
		goHome(item) {
			console.log(item);
			this.$router.push({
				name: 'Home',
				query: { id: item.id }
			});
		},
		startShake(event) {
			event.target.classList.add('shake');
		},
		stopShake(event) {
			event.target.classList.remove('shake');
		}
	}
}
</script>

<style scoped lang="scss">
.left-menu {
	padding: 20px;
	height: 100%;
	@include global.left-menu;
	margin-top: 70px;
}

.menu-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.menu-item {
	margin-bottom: 10px;
}

.menu-item-content {
	display: flex;
	align-items: center;
}

.menu-item-ico {
	margin-right: 10px;
}

.menu-ico {
	width: 40px;
	height: 40px;
	object-fit: contain;
	border-radius: 5px;
}

.menu-item-text {
	flex: 1;
}

.menu-link {
	color: white;
	text-decoration: none;
	font-weight: bold;
	display: block;
	padding: 10px;
	border-radius: 5px;
	transition: background-color 0.3s ease;
	@include global.transition-animation('text-color');
}

@keyframes shake {
	0% { transform: translateX(0); }
	25% { transform: translateX(-5px); }
	50% { transform: translateX(5px); }
	75% { transform: translateX(-5px); }
	100% { transform: translateX(0); }
}

.shake {
	animation: shake 0.5s infinite;
}
</style>
