<template>
	<div class="right-menu">
		<div>
			<ul class="menu-list">
				<li class="menu-item" v-for="(item, index) in rightMenuItems" :key="index" @click="selectItem(item)">
					<div class="menu-item-content">
						<div class="menu-item-icon">
							<img :src="item.icon" alt="Icon" class="menu-icon" />
						</div>
						<div class="menu-item-text">
							<a class="menu-link">{{ item.title }}</a>
						</div>
					</div>
				</li>
			</ul>
			<div class="load-data-view" v-if="rightMenuItems.length > 0">
				<button class="load-data-button" @click="loadData">Load more</button>
				<p class="load-data-p">
					<a :href="Wendow" style="text-decoration: none; color: #000;">{{ Wendow }}</a> </p>
			</div>
		</div>
	</div>
</template>

<script>
import indexApi from "../api/indexApi.js"

export default {
	name: 'RightMenu',
	props: {
		search: {
			type: String,
			default: ''
		},
		cid: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			rightMenuItems: [],
			pageNum: 1, // 初始化 pageNum 为 1
			gameId: false,
			Wendow:location.origin
		};
	},
	mounted() {
		this.toMenu()
	},
	watch: {
		search() {
			this.toMenu(true)
		},
		cid() {
			this.toMenu(true)
		}
	},
	methods: {
		async toMenu(type) {
			try {
				const res = await indexApi.ApifoxModel({
					"pageNum": this.pageNum,
					"pageSize": 35,
					title: this.search,
					cid: this.cid
				});
				console.log(res);
				const menuList = res.rows;
				const newItems = menuList.map(item => ({
					title: item.title,
					icon: item.cover,
					value: item.descr,
					gurl: item.gurl,
					id: item.id
				}));

				if (type) {
					this.rightMenuItems = newItems;
				} else {
					this.rightMenuItems = this.rightMenuItems.concat(newItems); // 追加新数据
				}

			} catch (error) {
				console.error('Error fetching menu data:', error);
			}
		},
		loadData() {
			this.pageNum += 1;
			this.toMenu();
		},
		selectItem(item) {
			this.$router.push({
				path: `/game/${item.id}`,
			})
		}
	}
}
</script>

<style scoped lang="scss">
.right-menu {
	@include global.right-RightMenu;
    box-sizing: border-box;
    width: 100vw;
    height: 100%;
    position: relative;
    max-width: 100%;
    overflow-x: scroll; /* 保持滚动功能 */
	margin-top: 70px;
}

.right-menu::-webkit-scrollbar {
    display: none; /* 隐藏滚动条 */
}

/* 针对 Firefox 的滚动条隐藏 */
.right-menu {
    // scrollbar-width: none; /* Firefox */
}

.menu-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	flex: 1;
}

.menu-item {
	margin-bottom: 20px;
	width: 100%;
	max-width: 130px;
	cursor: pointer;
}

.menu-item-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 15px;
	border-radius: 10px;
	// box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	text-align: center;
	transition: border 0.3s ease;
}


.menu-item-icon {
	margin-bottom: 10px;
}

.menu-icon {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.menu-item-text {
	flex: 1;
}

.menu-link {
	color: white;
	text-decoration: none;
	font-weight: bold;
	display: block;
	padding: 10px;
	border-radius: 5px;
	width: 90px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.menu-link:hover {
	color: black;
}

.menu-item-content:hover {
	border: 1px solid #fff;
}

.load-data-view {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 20px 0 100px 0;
	/* 添加一些间距 */
}

.load-data-button {
	width: 460px;
	height: 40px;
	background-color: global.$right-button;
	color: #fff;
	border: 1px solid #fff;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.load-data-button:hover {
	background-color: #fff;
	color: #000;
}

.load-data-p {
	margin-top: 10px;
}
</style>