// src/router/index.js
import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import DisclaimerView from '../views/DisclaimerView.vue'
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue'
import SitemapView from '../views/SitemapView.vue'
import GameDetail from '../views/GameDetail.vue'

// 定义路由配置
const routes = [{
		path: '/',
		redirect: '/home'
	},
	{
		path: '/home',
		name: 'Home',
		component: HomeView
	},
	{
		path: '/about',
		name: 'About',
		component: AboutView
	},
	{
		path: '/disclaimer',
		name: 'Disclaimer',
		component: DisclaimerView
	},
	{
		path: '/privacy-policy',
		name: 'PrivacyPolicy',
		component: PrivacyPolicyView
	},
	{
		path: '/sitemap',
		name: 'Sitemap',
		component: SitemapView
	},
	{
		path: '/game/:id',
		name: 'game',
		component: GameDetail
	}
]

// 创建路由实例
const router = createRouter({
	history: createWebHashHistory(),
	routes
})

// 添加路由守卫（可选）
// router.beforeEach((to, from, next) => {
//   // 示例：检查用户是否已登录
//   const isAuthenticated = false; // 假设用户未登录
//   if (to.name !== 'Home' && !isAuthenticated) next({ name: 'Home' })
//   else next()
// })

export default router