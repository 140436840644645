// store/index.js
import { createStore } from 'vuex'

export default createStore({
    state: {
        searchResults: []
    },
    mutations: {
        setSearchResults(state, results) {
            state.searchResults = results
        }
    },
    actions: {
        updateSearchResults({ commit }, results) {
            commit('setSearchResults', results)
        }
    }
})