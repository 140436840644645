<template>
	<div class="out_box">
		<uni-view data-v-6da7f2f4="" class="contents">
			<h2 data-v-6da7f2f4="" style="font-family: sans-serif; color: white;">SiteMap</h2>
			<iframe data-v-6da7f2f4="" src="https://www.misgames.site/sitemap.html" frameborder="0">
			</iframe>
		</uni-view>
	</div>
</template>

<script>
	export default {
		name: 'SitemapViewView'
	}
</script>
<style scoped lang="scss">
	.out_box {
		width: 100vw;
		height: 100vh;
		background-color: global.$text-view;
	}

	.contents {
		margin-top: 10px;
		/* padding: 0 9.375rem 9.375rem; */
		line-height: 1.5625rem;
		color: #fff;
	}


	h2 {
		font-family: 'Arial', sans-serif;
		color: #67a8ef;
		text-align: center;
		font-size: 2rem;
		margin-top: 2rem;
		margin-bottom: 1rem;
		border-bottom: 2px solid #ddd;
		padding-bottom: 0.5rem;
	}

	iframe {
		width: 200%;
		height: 50vh;
		border: none;
	}
</style>