<template>
	<header class="header">
		<nav class="nav-container">
			<router-link to="/" class="log-container">
				<div class="logo"></div>
				<!-- <img src="../assets/img/1.png" alt="Logo"/> -->
			</router-link>
			<div class="nav-list-container">
				<ul class="nav-list">
					<li class="nav-item" v-for="(item, index) in menuItems" :key="index">
						<router-link :to="item.path" class="nav-link">{{ item.label }}</router-link>
					</li>
				</ul>
				<div class="search-container">
					<input type="text" v-model="searchQuery" placeholder="Search" class="search-input"
						@keyup.enter="handleSearch" />
				</div>
			</div>
		</nav>
	</header>
</template>

<script>
// import indexApi from "../api/indexApi.js";

export default {
	name: 'TabMenu',
	props: {
		// menuItems: {
		// 	type: Array,
		// 	required: true
		// },
		menuLog: {
			type: Object,
			required: true,
			default: () => ({
				url: ""
			})
		}
	},
	data() {
		return {
			menuItems: [{
				label: 'Home',
				path: '/'
			},
			{
				label: 'About',
				path: '/about'
			},
			{
				label: 'Disclaimer',
				path: '/disclaimer'
			},
			{
				label: 'Privacy Policy',
				path: '/privacy-policy'
			}
			],
			searchQuery: '',

		};
	},
	methods: {
		async handleSearch() {
			// if (this.searchQuery.trim() === '') {
			// 	this.$emit('search-results', null);
			// 	return;
			// }
			// try {
			// 	const res = await indexApi.ApifoxModel({
			// 		"title": this.searchQuery
			// 	});
			// 	console.log(res);
			// 	const menuList = res.rows;
			// 	const newItems = menuList.map(item => ({
			// 		label: item.title,
			// 		icon: item.cover
			// 	}));
			// 	this.$emit('search-results', newItems);
			// } catch (error) {
			// 	console.error('Error fetching search data:', error);
			// 	this.$emit('search-results', null);
			// }

			this.$router.push({
				path: '/home',
				query: {
					search: this.searchQuery
				}
			})
			// console.log("Search Query:", this.searchQuery);

		},
		// handleSearchResults(results) {
		// 	this.searchResults = results;
		// }
	}
}
</script>

<style scoped lang="scss">
.header {
	height: 50px;
	width: 100%;
	color: white;
	@include global.tab-header;
	padding: 10px 0;
	z-index: 999;
	position: fixed;
	top: 0;
	left: 0;
}

.nav-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.log-container {
	margin-right: auto;
	margin-left: 50px;

}

.nav-list-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.nav-list {
	list-style-type: none;
	display: flex;
	justify-content: center;
	order: global.$tanmenu-order;
}

.nav-item {
	margin: 0 15px;
}

.nav-link {
	color: white;
	text-decoration: none;
	font-weight: bold;
	position: relative;
	padding: 5px 10px;
	border-radius: 5px;
	@include global.transition-animation('border-bottom-link');
}
.nav-link:hover{
	color: #0a0a0a;
}



.logo {
	width: 150px;
	height: 55px;
	background: global.$logo-link;
	background-size: global.$tanbackmin;
	// background-size: contain;
	background-position: center;
}

.search-container {
	// margin-left: auto;
	color: #fff;
	margin-right: 300px;
}

.search-input {
	padding: 15px 120px;
	border-radius: 25px;
	@include global.search-input;
	color: #75777d;
	display: block;

	/* 确保可见 */
}
</style>