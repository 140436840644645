<template>
	<div class="detail-view">
		<div class="detail-content">
			<div class="detail-gurl">
				<iframe :src="selectedItem.gurl" frameborder="0" class="iframe-gurl"></iframe>
			</div>
			<div class="detail-text">
				<a class="menu-link">{{ selectedItem.title }}</a>
				<p class="detail-description">
					{{ selectedItem.value }}
				</p>
			</div>
		</div>
		<div class="view_tuijian">
			<RightMenu :cid="selectedItem.cid" />
		</div>
	</div>

</template>

<script>
import indexApi from "../api/indexApi.js"
import RightMenu from '../components/RightMenu.vue'
export default {
	name: 'GameDetail',
	components: {
		RightMenu
	},
	data() {
		return {
			selectedItem: {},
		}
	},
	mounted() {
		const id = this.$route.params.id;
		if (id !== undefined && typeof id === 'string') {
			this.toGameItem(parseInt(id, 10)); // 确保 id 是一个数字
		} else {
			console.error("Invalid or missing id parameter:", id);
		}
	},
	watch:{
		'$route.params.id'(newValue){
			this.toGameItem(newValue)
		}
	},
	methods: {
		async toGameItem(id) {
			try {
				const result = await indexApi.ApigetInfo(id);
				const newItems = result.data; 
				console.log(newItems);

				if (newItems && typeof newItems === 'object') {
					this.selectedItem = {
						title: newItems.title || '', 
						value: newItems.descr || '', 
						gurl: newItems.gurl || ''  
					};
				} else {
					console.error("Invalid data structure:", newItems);
				}
			} catch (error) {
				console.error("Error fetching game item:", error);
			}
		}
	}
}
</script>

<style scoped lang="scss">
.detail-view {
	width: 100%;
	height: 100vh;
	display: flex;
	@include global.detail-view;
	justify-content: space-evenly;
	align-items: center;
	max-width: 100%;
	overflow-x: hidden;
	margin-top: 60px;
}

.detail-content {
    display: flex;
    flex-direction: column; /* 垂直排列子元素 */
    align-items: center; /* 水平居中对齐 */
    justify-content: space-between; /* 子元素间距均匀分布 */
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: border 0.3s ease;
    @include global.detail-content;

    /* 自适应宽度和高度 */
    width: calc(100% - 30px); /* 减去左右内边距 */
    max-width: 1200px; /* 设置最大宽度，避免过大 */
    height: auto; /* 高度自动调整 */
    min-height: 300px; /* 最小高度，防止内容过少时显得空旷 */
    // margin-top: -170px;
}

/* 调整 .detail-gurl 的自适应 */
.detail-gurl {
    width: 100%; /* 宽度占满父容器 */
    height: auto; /* 高度根据内容自动调整 */
    aspect-ratio: 16 / 9; /* 固定宽高比，避免变形 */
    border: none;
}

.iframe-gurl {
    width: 100%; /* 宽度占满父容器 */
    height: 100%; /* 高度占满父容器 */
}

/* 调整 .detail-text 的自适应 */
.detail-text {
    flex: 1; /* 占据剩余空间 */
    text-align: left; /* 左对齐文本 */
    padding: 10px; /* 添加内边距，避免内容贴边 */
}
.menu-link {
	color: white;
	text-decoration: none;
	font-weight: bold;
	display: block;
	padding: 10px;
	border-radius: 5px;
}
.detail-description {
    color: white;
    font-size: 16px;
    line-height: 1.5;
    margin-top: 10px;
    text-align: left;
}
.view_tuijian{
	width: 14%;
	height: 90vh;
    margin-top: -200px;
	border-radius: 20px;
}
</style>