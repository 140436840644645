<template>
	<div class="game_box">
		<TabMenu :menuLog="menuLog" @search-results="handleSearchResults" />

		<router-view></router-view>
	</div>
</template>

<script>
import TabMenu from '../components/TabMenu.vue'
export default {
	name: 'GameIndex',
	components: {
		TabMenu,
		// LeftMenu,
		// RightMenu
	},
	data() {
		return {

			menuLog: {
				url: "https://www.misgames.site/assets/logo-e-MFXQRF.png"
			},

			searchResults: null
		};
	},
	methods: {
		handleSearchResults(results) {
			console.log('Received search results:', results);
			this.searchResults = results;
		}
	}
}
</script>

<style scoped>
.game_box {
	width: 100vw;
	height: 100vh;
	/* max-width: 100%; */
	/* overflow: hidden; */
	overflow-x: hidden;
}
</style>